import React, { useCallback } from 'react';
import { navigate, graphql, Link } from 'gatsby';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import { SiteLayout, Head, Button, Field, Form } from '~/components';
import { IconError, IconArrowRight } from '~/icons';
import { useFormPersist } from '~/hooks';
import * as contact from '~/pages/contact/contact.module.scss';

const ContactPage = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;
  const { handleSubmit, register, errors, watch, setValue } = useForm();

  const goConfirm = useCallback((params) => {
    navigate('/contact/preview/', {
      state: {
        params,
        process: 'preview',
      },
    });
  }, [])

  useFormPersist('aizulab_contact_form', {
    watch,
    setValue,
  });

  return (
    <SiteLayout title={siteTitle}>
      <Head title="お問い合わせ" />

      <div className={contact.content}>

        <div className={contact.hero}>
          <h1 className={contact.title}>お問い合わせ</h1>
          <p className={contact.message}>休業日・祝日の前後はご回答が遅れる場合がございますのであらかじめご了承ください。<br />
            フォームからお問い合わせ頂きましたら折り返しご連絡いたします。</p>
          <div className={contact.flow}>
            <div className={contact.activeDot} />
            <div className={contact.dot} />
            <div className={contact.dot} />
          </div>
        </div>

        <Form noValidate onSubmit={handleSubmit(goConfirm)}>
          <div className={contact.inner}>
            <Field tag="任意" label="区分">
              <select
                name="subject"
                className="select-primary"
                ref={register}
                defaultValue="その他"
              >
                <option disabled>選択してください</option>
                <option value="アプリ開発">アプリ開発</option>
                <option value="業務システム開発">業務システム開発</option>
                <option value="Webサイトの構築">Webサイトの構築</option>
                <option value="UI/UX提案">UI/UX提案</option>
                <option value="EMSについて">EMSについて</option>
                <option value="採用について">採用について</option>
                <option value="宣伝・営業">宣伝・営業</option>
                <option value="重要・緊急">重要・緊急</option>
                <option value="その他">その他</option>
              </select>
            </Field>
            <Field className={contact.field} tag="必須" label="会社名（所属団体名）">
              <input
                type="text"
                name="company_name"
                className={clsx('input-primary', errors.company_name && 'is-error')}
                ref={register({
                  required: '会社名は必須項目です。',
                })}
                placeholder="株式会社会津ラボ"
              />
              <ErrorMessage
                name="company_name"
                errors={errors}
                render={({ message }) => <p className={contact.error}><IconError className={contact.icon} />{message}</p>}
              />
            </Field>
            <Field className={contact.field} tag="任意" label="部署">
              <input
                type="text"
                name="department"
                className="input-primary"
                ref={register}
                placeholder="クリエーション事業部"
              />
            </Field>
            <Field className={contact.field} tag="必須" label="氏名">
              <input
                type="text"
                name="sender_name"
                className={clsx('input-primary', errors.sender_name && 'is-error')}
                ref={register({
                  required: '氏名は必須項目です。',
                })}
                placeholder="会津太郎"
              />
              <ErrorMessage
                name="sender_name"
                errors={errors}
                render={({ message }) => <p className={contact.error}><IconError className={contact.icon} />{message}</p>}
              />
            </Field>
            <Field className={contact.field} tag="任意" label="ふりがな">
              <input
                type="text"
                name="sender_kana"
                className="input-primary"
                ref={register}
                placeholder="あいづたろう"
              />
            </Field>
            <Field className={contact.field} tag="必須" label="電話番号">
              <input
                type="tel"
                name="tel"
                className={clsx('input-primary', errors.tel && 'is-error')}
                ref={register({
                  required: '電話番号は必須項目です。',
                  pattern: {
                    value: /^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/,
                    message: '電話番号形式に誤りがあります。正しい内容を入力してください。',
                  },
                })}
                placeholder="0242-23-8285"
              />
              <ErrorMessage
                name="tel"
                errors={errors}
                render={({ message }) => <p className={contact.error}><IconError className={contact.icon} />{message}</p>}
              />
            </Field>
            <Field className={contact.field} tag="必須" label="メールアドレス">
              <input
                type="email"
                name="email"
                className={clsx('input-primary', errors.email && 'is-error')}
                ref={register({
                  required: 'メールアドレスは必須項目です。',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'メールアドレス形式に誤りがあります。正しい内容を入力してください。',
                  },
                })}
                placeholder="info@aizulab.com"
              />
              <ErrorMessage
                name="email"
                errors={errors}
                render={({ message }) => <p className={contact.error}><IconError className={contact.icon} />{message}</p>}
              />
            </Field>
            <Field className={contact.field} tag="必須" label="確認用メールアドレス">
              <input
                type="text"
                name="confirm_email"
                className={clsx('input-primary', errors.confirm_email && 'is-error')}
                ref={register({
                  required: '確認用メールアドレスは必須項目です。',
                  validate: value => value === watch('email') || 'メールアドレスに一致しません。',
                })}
                placeholder="確認のため再度ご入力ください"
              />
              <ErrorMessage
                name="confirm_email"
                errors={errors}
                render={({ message }) => <p className={contact.error}><IconError className={contact.icon} />{message}</p>}
              />
            </Field>
            <Field className={contact.field} tag="必須" label="お問い合わせ内容">
              <textarea
                name="comment"
                rows="8"
                placeholder="お問い合わせ内容をご記入ください"
                className={clsx('textarea-primary', errors.comment && 'is-error')}
                ref={register({
                  required: 'お問い合わせ内容は必須項目です。',
                })}
              />
              <ErrorMessage
                name="comment"
                errors={errors}
                render={({ message }) => <p className={contact.error}><IconError className={contact.icon} />{message}</p>}
              />
            </Field>
          </div>
          <div className={contact.inner}>
            <p className={contact.termsText}>お客様の個人情報は<Link to="/privacy/">プライバシーポリシー</Link>に従い管理されます。同意のうえ、送信してください。</p>
            <label className="checkbox-primary">
              <input
                type="checkbox"
                name="agree"
                className="checkbox-origin"
                ref={register({
                  required: '内容をご確認のうえ、チェックしてください。',
                })}
              />
              <span className="checkbox-label">プライバシーポリシーを確認し、同意します</span>
            </label>
            <ErrorMessage
              name="agree"
              errors={errors}
              render={({ message }) => <p className={contact.error}><IconError className={contact.icon} />{message}</p>}
            />
          </div>
          <div className={contact.controllbar}>
            <Button variant="Confirm" type="submit">確認</Button>
          </div>
        </Form>

        <dl className={contact.def}>
          <dt className={contact.defTitle}>■ お急ぎの場合はお電話にて問い合わせください。担当者が不在の場合は折り返しのご連絡となります。</dt>
          <dd className={contact.defText}>Tel <a className={contact.tel} href="tel:0242-23-8285">0242-23-8285</a> (平日 9:30~17:30)</dd>
        </dl>
      </div>
    </SiteLayout>
  )
}

export default ContactPage;

export const pageQuery = graphql`
  query {
      site {
      siteMetadata {
      title
    }
    }
  }
`
